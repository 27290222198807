import React from 'react'
import { SEO } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalWorkshop,
  InternalInstructor,
  InternalTestimonial,
  InternalWorkshopTab,
  InternalHowDoYouLearn
} from '../components/Internal'
import IconFlower from '../images/common/iconFlower.svg'
import { WHATSAPPLINK } from '../constants/routes'
import styles from './terapia-floral-sistemica.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Terapia Floral Sistêmica' />
      <InternalHeader className={styles.header} withType>
        <small>Online ou Presencial</small>
        <h1>
          Terapia Floral
          <br /> <strong>Sistêmica</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent>
            <h2>
              Sobre o <strong>Curso</strong>
            </h2>
            <p>
              Uma proposta inovadora para o desenvolvimento profissional de terapeutas que buscam um posicionamento de
              vanguarda no seu trabalho. O Curso de Terapia Floral Sistêmica está baseado no principio da consciência,
              entendimento e transformação de uma dificuldade através da observação dos princípios sistêmicos e da
              mudança de postura em relação à eles. A dinâmica das aulas foi concebida de forma a trazer para o
              terapeuta o entendimento dos princípios e posturas sistêmicas que fundamentam as relações, e são a base
              para a transformação daquilo que é difícil e tem sido um impeditivo no desenvolvimento pessoal e
              profissional do cliente.
            </p>
            <h2>Objetivo</h2>
            <p>
              O objetivo de cada módulo é embasar o terapeuta floral sistêmico para fazer a leitura sistêmica e
              identificar a origem do conflito e então montar a fórmula floral que irá ajudar a dissolver o bloqueio
              emocional do cliente e impulsionar seu processo de consciência, entendimento e transformação de uma
              dificuldade. Ao final dos módulos o terapeuta terá sua visão ampliada e desenvolvida no entendimento do
              “SER” que vai além da personalidade, do modo de ser da pessoa, mas olhará também para os comprometimentos
              e impedimentos nas suas relações familiares. Em cada módulo é feita uma avaliação dos vínculos invisíveis
              que norteiam os padrões de comportamento do cliente e a sugestão das essências Florais de Bach que irão
              estimular o desenvolvimento das competências emocionais importante no seu processo evolutivo.
            </p>
            <h2>
              O que você <strong>vai aprender</strong>
            </h2>

            <h3>Módulo 1 - Princípio e Postura Sistêmica</h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O princípio da Terapia Floral Sistêmica
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Campos Mórficos e a Alma Familiar
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                As Ordens do amor: hierarquia, pertencimento e equilíbrio{' '}
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                As Ordens da Ajuda e o terapeuta sistêmico
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Genograma
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A leitura Sistêmica e os Florais de Bach
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O campo emocional e a consciência vegetal
              </dt>
            </dl>

            <h3>Módulo 2 - A Criança Interior e os traumas de vinculação</h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A Criança Interior e os traumas de simbiose
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Da mãe recebi a vida
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Com o pai eu vou para vida
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Linha da vida
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A ação das essências florais dentro de cada
                dinâmica sistêmica
              </dt>
            </dl>

            <h3>Módulo 3 - Dinheiro, Carreira e Profissão</h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Êxito na vida, êxito na profissão
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O movimento em direção ao sucesso{' '}
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Encontrar e tomar a mãe
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Encontrar e tomar o pai
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A energia do dinheiro
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A ação das essências florais dentro de cada
                dinâmica sistêmica
              </dt>
            </dl>

            <h3>Módulo 4 - Relacionamento de Casal</h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                As 3 feridas emocionais que trazemos para a relação
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                As ordens do amor: hierarquia, pertencimento e equilíbrio nas relações de casal
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A força do feminino para mulher
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A força do masculino para homem
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A energia feminina, do masculino e seu encontro
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A ação das essências florais dentro de cada
                dinâmica sistêmica
              </dt>
            </dl>

            <h3>Módulo 5 - O olhar sistêmico sobre a doença, vícios e sobrepeso</h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Os laços familiares e as dinâmicas sistêmicas nas comorbidades
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O que é um sintoma?
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Vícios, compulsões e ansiedade
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Sobrepeso, pensamento sabotador e a fome emocional x fome fisiológica
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A ação das essências florais dentro de cada
                dinâmica sistêmica
              </dt>
            </dl>
            <br />
            <br />
            <InternalHowDoYouLearn />
          </InternalContent>
          <div className='aside'>
            <InternalWorkshop>
              <InternalWorkshopTab>
                <h3>
                  <small>Curso Online</small>
                  Terapia Floral Sistêmica
                </h3>
                <ul>
                  <li>Aulas online ao vivo pela plataforma digital de fácil interação</li>
                  <li>Aulas 1 vez na semana pela plataforma digital</li>
                  <li>Ênfase para o atendimento online </li>
                  <li>Tire suas dúvidas no momento que surgem ou no grupo de whats</li>
                  <li>Material complementar incluso</li>
                  <li>Aulas com prática e vivencia em constelação Familiar</li>
                  <li>Certificado de conclusão de 120hs</li>
                  <li>Pré-requisito: conhecimento prévio em Florais de Bach</li>
                  <li>Acesso a plataforma de ensino</li>
                </ul>
                <a
                  href={WHATSAPPLINK}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={`button button--purpleReverse internalWorkshop-button`}
                >
                  Inscrição Curso Online <small></small>
                </a>
              </InternalWorkshopTab>
              <InternalWorkshopTab>
                <h3>
                  <small>Curso Presencial</small>
                  Terapia Floral Sistêmica
                </h3>
                <ul>
                  <li>Encontros presenciais agendados conforme cronograma anual</li>
                  <li>Aulas 1 final de semana por mês</li>
                  <li>Ênfase para o atendimento presencial</li>
                  <li>Ambiente acolhedor</li>
                  <li>Amplamente prático e vivencial</li>
                  <li>Material complementar incluso</li>
                  <li>Certificado de conclusão</li>
                  <li>Pré-requisito: conhecimento prévio em Florais de Bach</li>
                  <li>Tire suas dúvidas ao vivo</li>
                </ul>
                <a
                  href={WHATSAPPLINK}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={`button button--purpleReverse internalWorkshop-button`}
                >
                  Inscrição Curso Presencial <small></small>
                </a>
              </InternalWorkshopTab>
            </InternalWorkshop>
            <InternalInstructor />
            <InternalTestimonial />
          </div>
        </div>
      </section>
    </>
  )
}
